<template>
  <div class="odometer-container force-center">
    <div class="parallax">
      <div class="centered-vertically">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="odometer-title">{{ $t('home.odometer.title') }}</h2>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="odometer-box">
                <vue-odometer class="iOdometer h1" :value="period" />
                <h5 class="h5">{{ $t('home.odometer.establishedOn') }}</h5>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="odometer-box">
                <vue-odometer class="iOdometer h1" :value="clients" />
                <h5 class="h5">{{ $t('home.odometer.clients') }}</h5>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="odometer-box">
                <vue-odometer class="iOdometer h1" :value="companies" />
                <h5 class="h5">{{ $t('home.odometer.companies') }}</h5>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="odometer-box">
                <vue-odometer class="iOdometer h1" :value="employees" />
                <h5 class="h5">{{ $t('home.odometer.staff') }}</h5>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  components: {},
  computed: {},
  data() {
    return {
      period: 0,
      clients: 0,
      companies: 0,
      employees: 0,
    };
  },
  methods: {},
  mounted() {
    let _this = this;
    jQuery($ => {
      $(window)
        .off()
        .on({
          'load scroll'() {
            let
              odometer = $('.odometer-container'),
              st = $(this).scrollTop();
            ////
            //console.log(odometer);
            if (odometer.length > 0 && (st + 350 >= odometer.offset().top)) {
              let date = new Date(),
                currentMonth = date.getMonth() + 1, // 0 => january :: 1 => january
                wholeMonths = 2 + (_this.year - 2008) * 12;
              _this.period = currentMonth + wholeMonths;
              _this.clients = 150;
              _this.companies = 30;
              _this.employees = 24;
            }
          },
        })
    });
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.odometer-container {
  background: fixed center 105px no-repeat
    url('http://www.malcolmdienes.com/content/client/1ea88dbd1ba7ab5f2fccbd4820dcb8ae/uploads/headers/oilfield.jpg');
  background-size: cover;
  position: relative;
  min-height: 400px;
  //
  .parallax {
    padding: 25px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .67);
    //
    .centered-vertically {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      //
      h2 {
        margin-bottom: 30px;
      }
      //
      hr {
        border-top-color: $lighted_color_2;
        max-width: 200px;
      }
      //
      .odometer-box {
        margin-bottom: 25px;
        //
        .iOdometer {
          display: inline;
        }        
      }
    }
  }
}
</style>