<template>
  <div class="slider">
    <vue-carousel
      v-bind:autoplay="true"
      v-bind:autoplayTimeout="6000"
      v-bind:items="1"
      v-bind:nav="false"
      v-bind:dots="false"
      v-bind:autoHeight="true">
      <div class="slide" v-for="(slide, i) in slides" v-bind:key="i">
        <img v-bind:src="image(slide.slide_image)" alt="" />
        <div class="slide-tiles" v-if="$engine.lang_json(slide.slide_title, lang) !== ''">
          <h2>{{ $engine.lang_json(slide.slide_title, lang) }}</h2>
          <p>{{ $engine.lang_json(slide.slide_text, lang) }}</p>
        </div>
      </div>
    </vue-carousel>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  props: ['slides'],
  components: {},
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  mounted() {
    //
  },
  methods: {
    image(src) {
      return this.$engine.storage(`slider/${src}`);
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
.slide {
  position: relative;
  //
  img {
    height: 500px;
    object-fit: cover;
    filter: brightness(.75);
  }
  //
  .slide-tiles {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    color: #fff;
    text-align: center;
    padding: 15px;
    background-color: rgba(black, .75);
    max-width: 550px;
    box-shadow: 0 0 5px 0 black;
    @include radius(5px);
    //
    p {
      margin: 0;
    }
  }
}
</style>