<template>
  <div class="section-box force-center">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 d-none d-sm-block">
          <div class="title-and-texts">
            <h2 class="h2"><b>{{ title }}</b></h2>
            <h6>{{ text }}</h6>
          </div>
        </div>
        <div class="col-sm-4 col-xs-12">
          <div class="icon-badge">
            <i v-bind:class="icon" aria-hidden="true"></i>
          </div>
        </div>
        <div class="d-block d-sm-none col-xs-12">
          <div class="title-and-texts">
            <h2 class="h2"><b>{{ title }}</b></h2>
            <h6>{{ text }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'title', 'text'],
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.section-box {
  //margin-bottom: 25px;
  background-color: $lighted_color_3;
  padding: 35px 10px;
  font-size: 18px;
  text-align: center;
  //border: 1px solid $lighted_color_2;
  //box-shadow: 0 0 3px 0px $lighted_color_2;
  //@include radius(3px);
  @include transitionDuration(.25s); 
  //
  &:hover {
    background-color: $lighted_color_2;
    //
    .icon-badge {
      left: 5px;
      i {
        //transform: scale(1.075);
        color: $main_color;
      }
    }
    //
    .title-and-texts {
      h2 {
        color: $main_color;
      }
    }
  }
  //
  .icon-badge {
    font-size: 28px * 2.5;
    margin-bottom: 10px;
    position: relative;
    left: 0;
    @include transitionDuration(.25s); 
    //
    i {
      @include transitionDuration(.25s); 
    }
  }
}
</style>