import Vue from 'vue';

Vue.directive('numericOnly', {
  bind(el, binding, vnode, oldVnode) {
    // console.log(el, binding);
    // this two prevent from copy&paste non-number text, including "e".
    // need to have both together to take effect.
    el.type = 'number';
    el.addEventListener('input', (e) => {
      // console.log('input', e);
      // console.log(el.validity);
      return el.validity.valid || (el.value = '');
    });
    // this prevents from typing non-number text, including "e".
    el.addEventListener('keypress', (e) => {
      let charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault();
      }
      else {
        return true;
      }
    });
  },
});

Vue.directive('foo', (el) => {
  // do something with the related el element
});

Vue.directive('bar', {
  bind(el, binding, vnode, oldVnode) {
    // do something with the related el element
  }
});

