<template>
  <div class="section-view" >
    <app-background-header
      v-bind:header="title"
      v-bind:title="``"
      v-bind:src="bg"
    />
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <article v-html="article"></article>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <app-loading v-if="isLoading" v-bind:size="50" />
          <div v-else class="sections">
            <div class="col-lg-12 col-md-6" v-for="(section, i) in sections" v-bind:key="i">
              <app-section v-bind:section="section" />
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionBox from '@/components/sections/SectionBox.vue';
export default {
  props: ['section'],
  components: {
    'app-section': SectionBox,
  },
  computed: {
    title() {
      return (this.$engine.lang_json(this.section.section_title, this.lang));
    },
    article() {
      return (this.$engine.lang_json(this.section.section_text, this.lang));
    },
    bg() {
      return this.$engine.storage(`sections/${this.section.section_image}`);
    },
  },
  data() {
    return {
      sections: [],
      isLoading: true,
    };
  },
  methods: {},
  mounted() {
    this.$engine.updateTitlePage(this.title);
    //
    let _this = this;
    this.$axios.get(this.$api, {
      params: {
        type: 'get-sections',
        id_section: this.id_section,
      }
    })
    .then(response => {
      let data = response.data;
      this.sections = data.sections;
      this.isLoading = false;
    })
    .catch(() => {
      //
    })
    .finally(() => {
      this.finishProgress();
      //
    });
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/_includes.scss';
//
.section-view {
  article {
    padding: 0 15px;
    font-size: 21px;
    //
    img {
      width: 100%;
      max-width: 750px;
      margin: 10px auto;
      display: block;
      height: auto;
    }
  }
}
</style>