<template>
  <div class="home height-fixer">
    <app-loading-component v-if="isLoading" />
    <div v-else>
      <app-slider
        v-if="slides.length > 0"
        v-bind:slides="slides"
      />
      <app-who-we-are />
      <app-slogan
        v-bind:slogan="$t('home.whatWeDo.slogan')"
        v-bind:speaker="$t('home.whatWeDo.speaker')"
      />
      <app-sections />
      <app-odometer />
      <app-branches
        v-if="branches.length > 0"
        v-bind="{branches: branches}"
      />
      <app-companies
        v-if="companies.length > 0"
        v-bind:companies="companies"
      />
      <app-contact-us />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from '@/components/home/Slider.vue';
import Sections from '@/components/home/Sections.vue';
import Slogan from '@/components/home/Slogan.vue';
import WhoWeAre from '@/components/home/WhoWeAre.vue';
import Companies from '@/components/home/Companies.vue';
import ContactUs from '@/components/home/ContactUs.vue';
import Odometer from '@/components/home/Odometer.vue';
import Branches from '@/components/home/Branches.vue';
//
export default {
  name: "Home",
  components: {
    'app-slider': Slider,
    'app-sections': Sections,
    'app-slogan': Slogan,
    'app-who-we-are': WhoWeAre,
    'app-companies': Companies,
    'app-contact-us': ContactUs,
    'app-odometer': Odometer,
    'app-branches': Branches,
  },
  data() {
    return {
      isLoading: true,
      slides: [],
      companies: [],
      branches: [],
    };
  },
  created() {
    //this.finishProgress(10000);
  },
  methods: {
    //
  },
  mounted() {
    let _this = this;
    this.$engine.updateTitlePage(this.$t('nav.home'));
    this.$axios.get(this.$api, {
      params: {
        type: 'get-home'
      }
    })
    .then(response => {
      let data = response.data;
      this.isLoading = false;
      this.slides = data.slider;
      this.companies = data.companies;
      this.branches = data.branches;
    })
    .catch(() => {
      //
    })
    .finally(() => {
      this.finishProgress(1000);
    });
  },
};
</script>

<style lang="scss">
//
</style>