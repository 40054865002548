<template>
  <div
    class="parallax force-center excepted"
    ref="parallax"
    v-bind:style="`background-image: url('${src}');`">
    <div class="centered-vertically">
      <div class="container">
        <h1>{{ header }}</h1>
        <h6>{{ title }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['src', 'header', 'title', 'bg_is_cover'],
  computed: {},
  components: {},
  data() {
    return {
      //
    };
  },
  methods: {},
  mounted() {
    if (this.bg_is_cover === false) {
      let parallax = this.$refs.parallax;
      parallax.style.backgroundSize = 'initial';
      parallax.style.backgroundPosition = 'center center';
      parallax.style.backgroundAttachment = 'scroll';
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.parallax {
  background: center -175px no-repeat fixed;
  background-size: cover;
  color: #fff;
  height: 200px;
  position: relative;
  margin-bottom: 25px;
  //
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .67);
  }
  //
  .centered-vertically {
    padding: 15px;
    z-index: 2;
  }
}
</style>
