<!-- eslint-disable -->
<template>
  <div
    class="spinner"
    v-bind:style="`width: ${size}px; height: ${size}px; margin: ${mtb}px auto;`">
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {};
  },
  props: {
    size: {
      default: 66,
      type: Number
    },
    mtb: {
      default: 10,
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
