/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */

let isLocalhost = (location.hostname === "localhost" || location.hostname === "127.0.0.1"),
baseUrl = (isLocalhost ? 'http://localhost/old-projects/majarah-group/' : 'http://www.majarahgroup.com/');

let engine = {
  clog(param) {
    console.log(param);
  },
  updateTitlePage(title) {
    if (title) {
      document.title = title + ' :: ' + this.baseTitleSuffix;
    }
    else {
      document.title = this.baseTitleSuffix;
    }
  },
  strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return '';
    }
    else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  },
  checkKeycode(e) {
    var keycode;
    if (window.event)
      keycode = window.event.keyCode;
    else if (e)
      keycode = e.which;

    // Mozilla firefox
    if ($.browser.mozilla) {
      if (keycode == 116 || (e.ctrlKey && keycode == 82)) {
        if (e.preventDefault) {
          e.preventDefault();
          e.stopPropagation();
          return true;
        }
        else {
          return false;
        }
      }
    }
    // IE
    else if ($.browser.msie) {
      if (keycode == 116 || (window.event.ctrlKey && keycode == 82)) {
        window.event.returnValue = false;
        window.event.keyCode = 0;
        window.status = "Refresh is disabled";
        return true;
      }
      else {
        return false;
      }
    }
  },
  showModal(text) {
    jQuery('.modal-hint')
      .addClass('md-show')
      .find('.modal-text').text(text);
  },
  mdModalClose() {
    return jQuery('.md-modal').removeClass('md-show');
  },
  lang_json(obj, lang) {
    obj = typeof obj !== 'object' ? JSON.parse(obj) : obj;
    return obj.lang === undefined ? obj[lang] : obj.lang;
  },
  storage(url) {
    return this.resourcesBaseURL + url;
  },
  youtubeEmbedGenerator(youtube_id) {
    return `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  },
  checkProperties(obj) {
    let arr = [];
    if (Object.keys(obj).length === 0) { return true; }
    else {
      for (let key in obj) {
        arr.push(obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
      }
      return arr.includes(false);
    }
  },
  //
  html                : document.getElementsByTagName('html')[0],
  body                : document.getElementsByTagName('body')[0],
  layout              : sessionStorage.layout === undefined ? 'ltr' : sessionStorage.layout,
  lang                : sessionStorage.lang === undefined ? 'en' : sessionStorage.lang,
  baseTitleSuffix     : 'Majarah Group',
  langs               : ['en', 'ar', 'ku'],
  APILink             : (isLocalhost ? `${baseUrl}api/api.php`  : 'http://api.majarahgroup.com/api.php'),
  resourcesBaseURL    : (isLocalhost ? `${baseUrl}storage/`     : 'http://storage.majarahgroup.com/'),
  asset(asset) {
    return isLocalhost ? `${baseUrl}www/src/assets/${asset}` : `http://www.majarahgroup.com/assets/${asset}`;
  },
  isLocalhost, baseUrl,
};

sessionStorage.layout = engine.layout;
sessionStorage.lang = engine.lang;

// engine.html.setAttribute('dir', engine.layout);
// engine.html.setAttribute('lang', engine.lang);

jQuery(function ($) {
  // jQuery block is here

  // console.log(engine.APILink);
  // console.log(engine.resourcesBaseURL);
  
  $.extend({
      replaceTag: function (currentElem, newTagObj, keepProps) {
          var $currentElem = $(currentElem);
          var i, $newTag = $(newTagObj).clone();
          if (keepProps) {//{{{
              newTag = $newTag[0];
              newTag.className = currentElem.className;
              $.extend(newTag.classList, currentElem.classList);
              $.extend(newTag.attributes, currentElem.attributes);
          }//}}}
          $currentElem.wrapAll($newTag);
          $currentElem.contents().unwrap();
          // return node; (Error spotted by Frank van Luijn)
          return this; // Suggested by ColeLawrence
      }
  });

  $.fn.extend({
      replaceTag: function (newTagObj, keepProps) {
          // "return" suggested by ColeLawrence
          return this.each(function() {
              jQuery.replaceTag(this, newTagObj, keepProps);
          });
      }
  });
  
  let loadingLayer = $('.loading-layer');
  //
  setTimeout(() => {
    loadingLayer.addClass('finished-loading');
  }, 1000);

  $(window).on({
    load: function () {
      //
    },
    'load scroll': function () {
      let st = $(this).scrollTop(),
          body = $('body')
      //
      if (!body.hasClass('fixed-navbar')) {
        if (st >= 100) {
          //body.addClass('scrolled');
        }
        else {
          //body.removeClass('scrolled');
        }
      }
    },
    resize: function () {
      $('.nav-links ul').removeClass('opened');
    }
  });

  $('.loading-layer').on({
    'animationend webkitAnimationEnd oAnimationEnd': function () {
      let $this = $(this);
      setTimeout(function () {
        $this.remove();
      }, 1000);
    }
  });
});

export default engine;
