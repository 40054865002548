export default {
  en: {
    misc: {
      copyright: `Copyright ©${new Date().getFullYear()} Majarah Group. All rights reserved.`,
      websiteDevelopedBy: "This website is developed with love by: <a href='http://www.naeldahman.me/' target='_blank'>Mohammad Nael Dahman</a>",
      add: "Add",
      edit: "Edit",
      prev: "Prev",
      next: "Next",
      delete: "Delete",
      cancel: "Cancel",
      continue: "Continue",
      back: "Back",
      areYouSure: "Are you sure?",
      unundoableAction: "You won't be able to revert this!",
      allUp: "All up!",
      video: "Video",
      checkout: "Checkout",
      address: "Address",
    },
    nav: {
      top: {
        slogan: "We are unique in offering our customers the best.",
        languages: "Languages",
      },
      home: "Home",
      about: "About us",
      contact: "Contact us",
      address: "Address",
      sections: "Sections",
    },
    footer: {
      address: [
        "Erbil, 40Meter st., MRF Quattro complex, tower B, 21th floor, 12th flat.",
        "Erbil, 100Meter st., near by Erbil customs center.",
        "Erbil, 100Meter st., Suzuki and Piston branch, in front of Cihan University.",
        "Sulaymaniyah, Industrial area 1st, in front of Sulaymaniyah court.",
        "Sulaymaniyah, Raparin, Kurdistan Motors Exhibitions, #35.",
      ],
      sitemap: {
        title: "Sitemap",
      },
    },
    modals: {
      languageSwitcher: {
        title: "Languages switcher",
        text: "Choose one of these available languages below to move on.",
      },
      phonePopup: {
        title: "Reach us easily",
        details: {
          sami: {
            name: "Sami Soliman",
          },
          menerva: {
            name: "Menerva Odisho",
            email: "",
          },
          yad: {
            name: "Yad Fairouz Arif",
            number: `009647704615272`,
          },
        },
      },
    },
    languages: {
      en: "English",
      ar: "Arabic",
      ku: "Kurdish",
    },
    errors: {
      404: {
        title: "Error",
        weAreSorry: "We are sorry!",
        firstP: "It seems that you are arrived at an unknown point of this website or maybe this page was exist from a while and removed by admin later.",
        secondP: "Please try to visit this page later if you believe that this was exist before, or you can contact admin below to inform him about this issue.",
        thirdP: "We are sorry about this experience but this is all what we know at this moment.",
      },
      messageNotSent: "Your message is not sent. Please retry or contact admin to fix this issue.",
    },
    home: {
      sections: {
        title: "Our sections",
        section_1: {
          title: "Oil derivatives trading",
          text: "After the great development that took place in the oil field in the Kurdistan Region, the company started to interest in this field and investment and expansion. However, we imported oil derivatives such as gasoline, gasoline and liquid gas. We also imported from the UAE, Turkmenistan, Azerbaijan and Turkey to the region. We have exported heavy oil derivatives such as black oil and asphalt to international markets.",
        },
        section_2: {
          title: "Cars Trading Ltd.",
          text: "Within the framework of the expansion and development campaign followed by the company was established a new business hub in the field of car trade during this year where the opening of a modern car showroom in the city of Erbil with the provision of the best services and the most suitable prices for customers.",
        },
        section_3: {
          title: "General trading",
          text: "One of the most important branches and interests of the company since its establishment is trade. The company obtained several high quality materials agency from several companies. It was then made available in the region's markets.",
        },
      },
      whatWeDo: {
        slogan: "We give services up-stream and down-stream with high performance team work.",
        speaker: "Mr. Yad Fairouz Arif - Executive Directer",
      },
      whoWeAre: {
        headerTitle: "Who we are",
        title: "Almajarah Company for Oil Services, General Trading and Car Trading Ltd.",
        text_1: `Almajarah was established in the city of Sulaymaniyah, Kurdistan Iraq on Novermber 15, 2007`,
        text_2: `Its main objective since its inception was to strengthen and stimulate trade and economic movement to and from the region and contribute effectively to the development of foreign trade with neighboring countries and the region.`,
        text_3: `Providing job opportunities for young people is the other goal of the company.`,
        text_4: `After success in the first year of operation, other branches of the company were opened in the cities of Erbil, Baghdad and Basra.`,
      },
      companies: {
        title: "We take pride in our customers",
      },
      branches: {
        title: "We take pride in our own companies",
      },
      odometer: {
        title: "We are proud of our achievements and ambitions",
        establishedOn: "Months of work and more",
        clients: "Clients we work with",
        companies: "Companies we work with",
        staff: "Employees we have",
      },
    },
    contact: {
      title: "Feel free to contact us",
      hint: "We are happy to get in touch with all our clients around the world. If you would like to ask us about anything you want to know, feel free to drop your message below to us.",
      form: {
        fullname: "Fullname",
        fullnameHint: "Tell us your fullname. Nice to meet you!",
        email: "Email",
        emailHint: "Leave your email. Be ensure that you use it frequently to contact you through it.",
        phone: "Phone number",
        phoneHint: "Type your phone number whatever it is mobile or landline in int'l format. Ex: 0044XXXXXXXXXXXXX",
        address: "Address",
        addressHint: "Type your exact address to reach you as fast as possible.",
        title: "Title",
        titleHint: "Tell us the title of your message briefly around 15 words maximum.",
        message: "Message",
        messageHint: "Feel free to tell us how could we help you.",
        send: "Send",
      },
      messageSentSuccessfully: "Your message has been sent successfully. Thank you.",
      //
    },
  },
  ar: {
    misc: {
      copyright: `©${new Date().getFullYear()} Majarah Group. جميع الحقوق محفوظة.`,
      websiteDevelopedBy: "تم تطوير هذا الموقع بكل شغف بواسطة: <a href='http://www.naeldahman.me/' target='_blank'>محمد نائل دهمان</a>",
      add: "إضافة",
      edit: "تعديل",
      prev: "السابق",
      next: "التالي",
      delete: "حذف",
      cancel: "إلغاء الأمر",
      continue: "متابعة",
      back: "رجوع",
      areYouSure: "هل أنت متأكد؟",
      unundoableAction: "بالمتابعة لا يمكنك التراجع عن هذا الأمر أبداً!",
      allUp: "تم بنجاح!",
      video: "فيديو",
      checkout: "إتمام الشراء",
      address: "العنوان",
    },
    nav: {
      top: {
        slogan: "متفردون في تقديم الأفضل لعملائنا",
        languages: "اللغات",
      },
      home: "الرئيسية",
      about: "من نحن",
      contact: "إتصل بنا",
      address: "العنوان",
      sections: "الأقسام",
    },
    footer: {
      // address: "العراق, إقليم كردستان العراق, أربيل, شارع 40 متري, مجمع MRF Quattro, بناء B, طابق 21, شقة رقم 12",
      address: [
        "أربيل, شارع 40 متري, مجمع MRF Quattro, بناء B, طابق 21, شقة رقم 12",
        "Erbil, 100Meter st., near by Erbil customs center.",
        "أربيل, شارع 100 متري, فرع سوزوكي وبيستون, مقابل جامعة جيهان.",
        "السليمانية, المنطقة الصناعية الأولى, مقابل محكمة السليمانية.",
        "السليمانية, رابرين, معرض كوردستان موتورز, رقم 35.",
      ],
      sitemap: {
        title: "خريطة الموقع",
      },
    },
    modals: {
      languageSwitcher: {
        title: "تبديل اللغة",
        text: "إختر اللغة التي تريد أن تتصفح فيها موقعنا للمتابعة.",
      },
      phonePopup: {
        title: "في خدمتكم دوماً",
        details: {
          sami: {
            name: "سامي سليمان",
          },
          menerva: {
            name: "مينيرفا أوديشو",
          },
          yad: {
            name: "ياد فيروز عارف",
          },
        },
      },
    },
    languages: {
      en: "الإنكليزية",
      ar: "العربية",
      ku: "الكردية",
    },
    errors: {
      404: {
        title: "خطأ",
        weAreSorry: "يبدو أن هنالك مشكلة ما!",
        firstP: "يبدو أنك وصلت إلى نقطة غير معروفة من هذا الموقع أو ربما كانت هذه الصفحة موجودة من حين إلى آخر وتم إزالتها من قبل المسؤول في وقت لاحق.",
        secondP: "يرجى محاولة زيارة هذه الصفحة في وقت لاحق إذا كنت تعتقد أن هذا كان موجوداً من قبل، أو يمكنك الاتصال بمدير الموقع أدناه لإبلاغه بهذه المشكلة.",
        thirdP: "نأسف لهذه التجربة ولكن هذا كل ما نعرفه في هذه اللحظة.",
      },
      messageNotSent: "لم يتم إرسال رسالتك. يمكنك المحاولة بعد دقائق أو إتصل بمدير الموقع لإبلاغه بالمشكلة.",
    },
    home: {
      sections: {
        title: "أقسامنا",
        section_1: {
          title: "تجارة المشتقات النفطية وخدماتها",
          text: "بعد التطور الكبير الذي حصل في المجال النفطي في إقليم كوردستان, بدأت الشركة بالإهتمام بهذا المجال والإستثمار والتوسع فيه حيث قمنا بإستيراد المشتقات النفطية مثل البنزين والكازوايل والغاز السائل من دول الإمارات وتركمنستان وأذربيجان وتركيا إلى الإقليم.",
        },
        section_2: {
          title: "تجارة السيارات المحدودة",
          text: "في إطار حملة التوسيع والتطوير التي تتبعها الشركة تم تأسيس محور نشاط تجاري جديد في مجال تجارة السيارات خلال هذا العام  حيث تم افتتاح معرض للسيارات الحديثة في مدينة أربيل مع توفير أفضل الخدمات وأنسب الاسعار للعملاء.",
        },
        section_3: {
          title: "التجارة العامة",
          text: "أحد أهم فروع وإهتمامات الشركة منذ تأسيسها هو التجارة. حصلت الشركة على وكالة عدة مواد ذات جودة عالية من عدة شركات ومن ثم تم توفيرها وطرحها في أسواق الإقليم.",
        },
      },
      whatWeDo: {
        slogan: "نعتز بتقديم خدماتنا لكل عملائنا بأعلى جودة وبروح الفريق.",
        speaker: "السيد ياد فيروز عارف - المدير التنفيذي",
      },
      whoWeAre: {
        headerTitle: "نبذة عنا",
        title: "شركة المجرة للخدمات النفطية و التجارة العامة و تجارة السيارات المحدودة.",
        text_1: `تأسست شركة المجرة في مدينة السليمانية –  اقليم كوردستان العراق بتاريخ 15 / 11 / 2007`,
        text_2: `حيثُ كان هدفها الاول منذ التأسيس تدعيم و تنشيط الحركة التجارية والإقتصادية من وإلى الإقليم والمساهمة بشكل فعال في تطوير التجارة الخارجية مع دول الجوار والمنطقة.`,
        text_3: `توفير فرص العمل للشباب هو الهدف الآخر من تأسيس الشركة.`,
        text_4: `وبعد النجاح في السنة الأولى للتأسيس, تم إفتتاح أفرع اُخرى للشركة في مدن أربيل وبغداد والبصرة`,
      },
      companies: {
        title: "نفخر بعملائنا",
      },
      branches: {
        title: "نفخر بشركاتنا",
      },
      odometer: {
        title: "فخورون بإنجازاتنا وطموحاتنا",
        establishedOn: "شهر مضى على تأسيس الشركة",
        clients: "عميل نفخر بالعمل معهم",
        companies: "شركات نشاركها العمل",
        staff: "موظف في شركتنا",
      },
    },
    contact: {
      title: "لا تتردد في الإتصال بنا",
      hint: "يسعدنا التواصل مع جميع عملائنا في جميع أنحاء العالم. إذا كنت تريد أن تسألنا عن أي شيء تريد معرفته، فلا تتردد في ترك رسالتك أدناه إلينا.",
      form: {
        fullname: "الإسم الكامل",
        fullnameHint: "أخبرنا ما هو إسمك الكامل. سررنا بمعرفتك!",
        email: "البريد الإلكتروني",
        emailHint: "الرجاء كتابة بريدك الإلكتروني. تأكد من أنك تتصفحه بشكل متكرر لنتمكن من التواصل معك من خلاله.",
        phone: "رقم الهاتف",
        phoneHint: "الرجاء كتابة رقم هاتفك سواءً كان هاتفاً محمولاً أو هاتفاً أرضياً مع مراعاة كتابة الرمز الدولي الخاص بدولتك. مثال: 00964xxxxxxxxxx",
        address: "العنوان الحالي",
        addressHint: "أخبرنا بعنوانك الحالي بشكل دقيق ومفصل وواضح. هذا سيساعدنا على توصيل منتجاتك بأسرع وقت ممكن.",
        title: "عنوان رسالتك",
        titleHint: "أخبرنا بعنوان رسالتك بشكل مختصر حوالي 15 كلمة كحد أقصى.",
        message: "الرسالة",
        messageHint: "لا تتردد في إخبارنا كيف يمكننا مساعدتك.",
        send: "أرسل",
      },
      messageSentSuccessfully: "تم إرسال رسالتك بنجاح. شكراً لك.",
      //
    },
  },
  ku: {
    misc: {
      copyright: `©${new Date().getFullYear()} Almajarah Group. هەموو مافەکان پارێزراون.`,
      websiteDevelopedBy: "ئەم مالپەڕە بە هەموو تواناوە گەشەی پێدراوە لە ڕێی: <a href='http://www.naeldahman.me/' target='_blank'>محمد نائل دهمان</a>",
      add: "زیادکردن",
      edit: "گۆڕانکاری",
      prev: "پێشوو",
      next: "پاش",
      delete: "سڕینەوە",
      cancel: "هەلوەشاندنەوە",
      continue: "بەردەوامی",
      back: "گەڕانەوە",
      areYouSure: "ئایا دلنیایت؟",
      unundoableAction: "بە داگرتنی بەردەوامی هەرگیز ناتوانیت  لەم هەنگاوە بگەڕێیتەوە!",
      allUp: "بەسەرکەوتویی ئەنجامدرا!",
      video: "ڤیدیۆ",
      checkout: "تەواوکردنی کڕین",
      address: "ناونيشان",
    },
    nav: {
      top: {
        slogan: "تاكە كۆمپانياين لەپيشكەش كردن باشترينی خزمەت بۆ (كڕِياران)",
        languages: "زمان",
      },
      home: "لاپەڕەی سەرەکی",
      about: "دەربارەی ئێمە",
      contact: "پەیوەندیمان پێوە بکە",
      sections: "بەشەکان",
    },
    footer: {
      // address: "عیراق, هەریمی کوردستان, هەولێر, جادە 40 مەتری, MRF Quattro, بینای B, قاتی 21، شوقەی 12",
      address: [
        "هەولێر, جادە 40 مەتری, MRF Quattro, بینای B, قاتی 21، شوقەی 12.",
        "هەولێر, جادەی 100مەتری, تەنیشت گومرگی هەولێر.",
        "هەولێرشەقامی 100 مەتری, لقی سوزوکی و بێستون, بەرامبەر زانکۆی جیهان.",
        "سلێمانی, ناوچەی پیشەسازی 1 بەرامبەر دادگای سلێمانی.",
        "سلێمانی, ڕاپەڕین _ پێشانگاکانی کوردستان مۆتۆرز _ پێشانگای ژمارە 35.",
      ],
      sitemap: {
        title: "نەخشەی مالپەڕەکە",
      },
    },
    modals: {
      languageSwitcher: {
        title: "گۆڕینی زمان",
        text: "ئەو زمانە هەلبژێرە کە دەتەوێت لە مالپەڕەکەماندا بگەڕێیت بۆ بەردەوامبوون.",
      },
      phonePopup: {
        title: "لەخزمەتاين هەميشە",
        details: {
          sami: {
            name: "سامي سليمان",
          },
          menerva: {
            name: "مينيرفا أوديشو",
          },
          yad: {
            name: "ياد فيروز عارف",
          },
        },
      },
    },
    languages: {
      en: "ئینگلیزی",
      ar: "عەرەبی",
      ku: "کوردی",
    },
    errors: {
      404: {
        title: "هەلە",
        weAreSorry: "پێدەچێت کێشەیێک هەبێت!",
        firstP: "پێدەچێت گەیشتبیت بەخالێکی نەناسراو لەم مالپەڕەدا یانیش لەوانەیە ئەم ڵاپەڕەیە لە ماوەیێک تا ماوەیێکی تر هەبوبێت و دواتر لابرابێت لەلایەن خاوەندارەکەی دواترەوە.",
        secondP: "تکایە کاتێکی تر هەولی سەردانکردنی ئەم ڵاپەڕەیە بدەرەوە ئەگەر بڕوات وابێت کە ئەم ڵاپەڕەیە پێشتر بوونی هەبووە، یانیش دەتوانی پەیوەندی بە بەڕێوەبەری  مالپەڕەکەوە بکەیت لەخوارەوە بۆ  پێڕاگەیاندنی ئەم کێشەیە.",
        thirdP: "داوای لێبووردن دەکەین بۆ ئەم هەولدانە بەلام ئەمە هەموو ئەو شتەیە کە ئێمە لەم ساتەدا دەیزانین.",
      },
      messageNotSent: "لم يتم إرسال رسالتك. يمكنك المحاولة بعد دقائق أو إتصل بمدير الموقع لإبلاغه بالمشكلة.",
    },
    home: {
      sections: {
        title: "بەشەکان",
        section_1: {
          title: "خزمەت گوزارى نەوتی",
          text: "دواي ئەو پێشكەوتنە گەورەيەى كە لە بواری نەوتدا لە هەريمى كوردستان رويدا, كۆمپانياكەمان گرنگييەكى تايبەتى بەم بوارە داوە و وەبەرهينانى لەم بوارەدا كردوە, كە سەرەتا دەستكرا بە هاوردەكردنى بەرهەمە نەوتيەكان  بونمونە (بەنزين, گازوايل, غازي شل, نەفتا) لە وڵاتانى (الامارات وتركمانستان وأذربيجان وتركيا) بو هةرێم هاوردە دەكرێت هةروەها بە هەناردەكردني بەرهەمە نەوتيە قورسكان وةك (نەوتي رەش وقير) بۆبازاري جيهانى.",
        },
        section_2: {
          title: "بازرگانی ئۆتۆمبێل - سنوردار",
          text: "لة چوارچێوەى هەولەكانمان بۆگەورەكردن وبەرەوپێش بردنى كۆمپانياكەمان دەستمان كرد بە بازرگانى كردن لەبواری ئۆتۆمبێل دا لەسالی 2019 دا ئەويش بەكردنەوەيێ پێشانگايكی گەورەى ئۆتۆمبێل وبە پێشكەوتو ترين وباشترين شێواز بۆ خزمەتكردنی هاوڵاتيان.",
        },
        section_3: {
          title: "بازرگانی گشتی",
          text: "يەكيك لەبەشە هەرە گرنگە كانی كۆمپانيا لە يەكەم سالي دامەزراندمانەوە كۆمپانياكەمان زۆريك لەبريكارەكانى كاڵا جيهانيەكانيەكانمان وەرگرتووە ودابينمان كردووە لەبازارەكانى هةرَێمدا.",
        },
      },
      whatWeDo: {
        slogan: "شانازی دەكين بەپیشكةش كردنی خزمەت گوزاری يەكانمان بۆ (كڕياران) بە بەرزترين كواليتى و بەشيوەی يەك دەست",
        speaker: "بەرێز: یاد فیروز عارف –  بەریوبەری رێگە پێدراو",
      },
      whoWeAre: {
        headerTitle: "دامەزراندن",
        title: "كؤمپانياى (المجرة) بۆ خزمەت گوزاری نەوت وبازرگانی گشتی وبازرگانى ئۆتۆمبێل - سنوردار.",
        text_1: `كۆمپانياي (المجرة) لەشاری سليمانى – هةرێمى كوردستانى عێراق دامە زراوە لەبەرواری 15-11-2007 دامەزراوە.`,
        text_2: `يەكيك لەوئامانجە سەرەكييانەی كەكۆمپانياكە مان بۆی دامەزراوە بەرەو پيش بردني جولەی بازرگانی وئابورييە لەهەريمى كوردستاندا وبةرةو پيشبردنى بازرگانى ناوخۆی لەگەل ولاتانى دەرەوە وناوجەكە.`,
        text_3: `دابين كردنی هةلی كار بوگەنجان يەکێكە لەو ئامانجانەى كۆمپانياى بۆى دامەزراوە.`,
        text_4: `دوا بە دوايي ئەو پيشكەوتنەی كە لە يەكەم سالي دامةزراندنيدا روويدا بۆيە برياردرا بە كردنەوەي لقةكانى كۆمپانياكە مان لە شارەكانى (هەولير, بغداد, بصرة).`,
      },
      companies: {
        title: "كۆمپانياكان كە هاوبەشمانن لە كارە كانماندا",
      },
      branches: {
        title: "كۆمپانياكان كە هاوبەشمانن لە كارە كانماندا",
      },
      odometer: {
        title: "شانازی دەكەين بە دەسكەوتە كانمان وئاواتەكانمان",
        establishedOn: "مانگيك بەسەر دروست بوندا",
        clients: "شانازی دەكەين بە كاركردن لەگەڵ كرياراندا",
        companies: "كۆمپانياكان كە هاوبەشمانن لە كارە كانماندا",
        staff: "كارمەند لەكۆمپانياكانماندا",
      },
    },
    contact: {
      title: "پەيوەندى كردن بە كۆمپانياكە مانەوە",
      hint: "پەیوەندیکردنمان لەگەل هەموو بەشداربووەکانمان لەسەرتاسەری جیهان خۆشحالمان دەکات. ئەگەر دەتەوێت لەبارەی هەر شتێکەوە پرسیارمان لێبکەیت و لە بارەیەوە بزانیت درێغی مەکە لە بەجێهێشتنی نامەکەت بۆمان لە خوارەوە.",
      form: {
        fullname: "ناوی تەواو",
        fullnameHint: "پێمان ڕابگەیێنە کە ناوی تەواوت چیە. خۆشحالبووین بەناسینت!",
        email: "ناونیشانی ئەلیکترۆنی",
        emailHint: "تکایە ناونیشانە ئەلیکترۆنیەکەت بنووسە. دلنیابەرەوە کە بەشێوەییکی بەردەوام و دووبارە چاودێریی دەکەیت بۆ ئەوەی بتوانین لەو ماوەیەدا پەیوەندیت پیوە بکەین.",
        phone: "ژمارەی تەلەفۆنەکەت",
        phoneHint: "تکایە ژمارەی تەلەفۆنەکەت بنووسە چ مۆبایل (گەڕۆک) بێت چ تەفۆن (نۆڕمال) بێت لەگەل ڕەچاوکردنی نووسینی کۆدی نێودەولەتی تایبەت بە وولاتەکەت. بۆنموونە: 00964xxxxxxxxxx",
        address: "ناونیشانی ئێستا",
        addressHint: "ناونیشانی ئێستات بە شێوەیێکی تەواو و ڕوون و ئاشکرا پێمان ڕابگەیێنە. ئەمە یارمەتیمان دەدات تا بە خێراترین کاتی شیاو بەرهەمەکانت پێبگەیێنین.",
        title: "ناونیشانی نامەکەت",
        titleHint: "ناونیشانی نامەکەت بەشێوەیێکی کورت پیمان ڕابگەیێنە بەشێوەیێک کە لە 15 ووشە تیپەڕ نەبێت.",
        message: "نامە",
        messageHint: "دوو دڵ مەبە لە لەوەی کە پیمان ڕابگەیێنی چۆن دەتوانین یارمەتیت بدەین.",
        send: "بینێرە",
      },
      messageSentSuccessfully: "داواکاریەکەت بەسەرکەوتویی نێردرا!",
      //
    },
  },
};
