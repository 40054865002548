<template>
  <div class="fixed-layout language-switcher"
    ref="language_switcher"
    v-on:click.self="closeLanguageSwitcherPopup">
    <div class="fixed-modal language-modal">
      <h4>
        <i class="fa fa-flag has-margin" aria-hidden="true"></i>
        <b>{{ $t('modals.languageSwitcher.title') }}</b>
      </h4>
      <hr />
      <h6><b>{{ $t('modals.languageSwitcher.text') }}</b></h6>
      <ul class="languages">
        <li v-for="(lang, i) in $engine.langs" v-bind:key="i" v-on:click="switchLanguage(lang)">
          <img :src="`/flags/${lang}.png`" :alt="lang" />
          {{ $t(`languages.${lang}`) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  components: {},
  data() {
    return {
      //
    };
  },
  methods: {
    closeLanguageSwitcherPopup() {
      return this.$refs.language_switcher.classList.remove('active');
    },
    switchLanguage(lang) {
      this.$session.set('lang', lang);
      if (lang === 'en') {
        this.$session.set('dir', 'ltr');
      }
      else {
        this.$session.set('dir', 'rtl');
      }
      this.$router.go();
    },
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.languages {
  img {
    display: inline-block;
    max-width: 50px;
    margin: 0 15px;
  }
}
</style>