<template>
  <footer>
    <div class="container footer-links">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 footer-cols">
          <div class="footer-tile">
            <h4>
              <i class="fa fa-info has-margin" aria-hidden="true"></i>
              <b>{{ $t('footer.sitemap.title') }}</b>
            </h4>
          </div>
          <ul class="">
            <li><router-link to="/sections">{{ $t('nav.sections') }}</router-link></li>
            <li><router-link to="/about">{{ $t('nav.about') }}</router-link></li>
            <li><router-link to="/contact">{{ $t('nav.contact') }}</router-link></li>
          </ul>
        </div>
        <div class="col-xl-5 col-lg-4 col-md-6 col-sm-12 col-xs-12 footer-cols">
          <div class="footer-tile">
            <h4>
              <i class="fa fa-map-marker has-margin" aria-hidden="true"></i>
              <b>{{ $t('misc.address') }}</b>
            </h4>
          </div>
          <article>
            <p v-for="(item, index) in $t('footer.address')" :key="index">- {{ item }}</p>
          </article>
        </div>
        <div class="col-xl-4 col-lg-4 footer-cols">
          <div class="footer-slogan">{{ $t('home.whatWeDo.slogan') }}</div>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 copyright force-center">
          <hr />
          <p>{{ $t('misc.copyright') }}</p>
          <p v-html="$t('misc.websiteDevelopedBy')"></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: [],
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.footer-cols {
  // margin-bottom: 25px;
}
//
.footer-slogan {
  background-color: darken($main_color, 10);
  padding: 10px;
  box-shadow: 0 0 5px 0 darken($main_color, 10);
  @include radius(3px);
}
</style>