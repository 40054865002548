<template>
  <div class="section-box">
    <div class="image">
      <router-link v-bind:to="`/sections/${section.id}`">
        <div class="layout force-center">
          <h3 class="h3">{{ $engine.lang_json(section.section_title, lang) }}</h3>
        </div>
      </router-link>
      <img
        v-bind:src="$engine.storage(`sections/${section.section_image}`)"
        v-bind:alt="$engine.lang_json(section.section_title, lang)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['section'],
  computed: {},
  components: {},
  data() {
    return {
      //
    };
  },
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.section-box {
  margin-bottom: 15px;
  overflow: hidden;
  @include radius(5px);
  //
  &:hover {
    .image {
      img {
        @include transform(scale(1.1));
      }
      .layout {
        opacity: 1;
        //
        h3 {
          @include transform(translate(-50%, -50%));
        }
      }
    }
  }
  //
  .image {
    position: relative;
    overflow: hidden;
    //
    img {
      @include transitionDuration(.25s);
    }
    //
    a {
      text-decoration: none;
      //
      .layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba($main_color, .75);
        cursor: pointer;
        color: #fff;
        padding: 25px;
        opacity: 0;
        @include transitionDuration(.25s);
        //
        h3 {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          @include transform(translate(-50%, -100%));
          @include transitionDuration(.25s);
        }
      }
    }
    //
    img {
      display: block;
      width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }
}
</style>