<template>
  <div class="error container force-center height-fixer- ptb-25">
    <h1>{{ $t('errors.404.title') }}</h1>
    <h2>{{ $t('errors.404.weAreSorry') }}</h2>
    <hr />
    <p>{{ $t('errors.404.firstP') }}</p>
    <p>{{ $t('errors.404.secondP') }}</p>
    <p>{{ $t('errors.404.thirdP') }}</p>
    <hr />
    <p>{{ $t('misc.copyright') }}</p>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: [],
  computed: {},
  components: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>