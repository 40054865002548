import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios';
import translation from './translation';
import engine from '@/assets/js/engine.js';
import '@/directives';
import mainMixin from '@/mixins/mainMixin.js';

import VueSession from 'vue-session';
import VueProgressBar from 'vue-progressbar';
import Multiselect from 'vue-multiselect';
import VueMoment from 'vue-moment';
import VueI18n from 'vue-i18n';
import carousel from 'vue-owl-carousel';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueSweetalert2 from 'vue-sweetalert2';
import IOdometer from 'vue-odometer';
import 'odometer/themes/odometer-theme-default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';
import 'bootstrap/dist/js/bootstrap.min.js';

import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';
import Loading from './components/Loading.vue';
import LoadingComponent from './components/LoadingComponent.vue';
import Success from './components/SA-Success.vue';
import Error from './components/SA-Error.vue';
import Warning from './components/SA-Warning.vue';
import BackgroundHeader from './components/BackgroundHeader.vue';

Vue.use(VueSession);
Vue.use(VueProgressBar, {
  color: 'rgb(88, 88, 212)',
  failedColor: '#df6b7c',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: false,
  location: 'top',
  inverse: false,
  autoFinish: false,
});
Vue.use(VueMoment);
Vue.use(VueI18n);
Vue.use(VueSweetalert2);

Vue.component('app-header', Header);
Vue.component('app-footer', Footer);
Vue.component('app-loading', Loading);
Vue.component('app-loading-component', LoadingComponent);
Vue.component('app-success', Success);
Vue.component('app-warning', Warning);
Vue.component('app-error', Error);
Vue.component('app-background-header', BackgroundHeader);
Vue.component('vue-multiselect', Multiselect);
Vue.component('vue-carousel', carousel);
Vue.component('vue-number-input', VueNumberInput);
Vue.component('vue-odometer', IOdometer);

Vue.prototype.$axios = axios;
Vue.prototype.$engine = engine;
Vue.prototype.$api = engine.APILink;
Vue.prototype.storagedir = engine.resourcesBaseURL;

const i18n = new VueI18n({
  locale: 'en', // this is a default locale
  messages: translation
});

Vue.config.productionTip = false;

Vue.mixin(mainMixin);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
