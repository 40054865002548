<template>
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <img v-bind:src="image" class="img-fluid rounded mx-auto d-block" alt="" />
    </div>
    <div class="col-md-12 col-xs-12">
      <article v-html="$engine.lang_json(about.about_text, lang)"></article>
    </div>
    <div class="clearfix" style='padding: 50px 0;'></div>
  </div>
</template>

<script>
export default {
  props: ['about', 'i'],
  computed: {
    image() {
      return this.$engine.resourcesBaseURL + 'about/' + this.about.about_image;
    },
  },
  mounted() {
    //console.log(this.i);
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
img {
  margin: 25px 0;
}
</style>
