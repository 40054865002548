<template>
  <div class="sections">
    <div>
      <app-section-box
        icon='fa fa-fire'
        v-bind:title="$t('home.sections.section_1.title')"
        v-bind:text="$t('home.sections.section_1.text')"
      />
      <app-section-box
        icon='fa fa-truck'
        v-bind:title="$t('home.sections.section_2.title')"
        v-bind:text="$t('home.sections.section_2.text')"
      />
      <app-section-box
        icon='fa fa-exchange'
        v-bind:title="$t('home.sections.section_3.title')"
        v-bind:text="$t('home.sections.section_3.text')"
      />
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import SectionsBox from '@/components/home/SectionsBox.vue';
export default {
  props: [],
  components: {
    'app-section-box': SectionsBox,
  },
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.sections {
  //padding: 50px 0;
  //
  h2 {
    margin-bottom: 25px;
  }
  //
}
</style>