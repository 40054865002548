export default {
  beforeCreate() {
    if (!this.$session.exists()) {
      // no sessions yet. This is the first visit for this user
      // let's set some basic settings
      this.$session.set('lang', 'en');
      this.$session.set('dir', 'ltr');
    }
    let lang = this.$session.get('lang');
    this.$i18n.locale = lang;
    $('html').attr('dir', this.$session.get('dir'));
    $('html').attr('lang', lang === 'ku' ? 'ar' : lang);
    //
    if (this.$route.path === '/') {
      setTimeout(() => {
        //
      }, 100);
    }
    else {
      //
    }
    //
  },
  created() {
    // start the progress bar
    this.startProgress();
    this.$router.beforeEach((to, from, next) => {
      jQuery('.nav-links ul').removeClass('opened');
      setTimeout(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
      }, 1);
      //console.log('test created is triggered');
      //  continue to next page
      next();
    });
    this.$router.afterEach((to, from) => {
      //console.log(to);
      if (to.name === "Error") {
        // it means that the requested route is not defined or maybe it is bad url.
        // we should finish the Progressbar
        this.finishProgress();
      }
      //  finish the progress bar
      //this.$Progress.finish();
    });
    //
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    lang() {
      return this.$session.get('lang');
    },
    dir() {
      return this.$session.get('dir');
    },
  },
  methods: {
    startProgress() {
      this.$Progress.start();
    },
    finishProgress(afterSeconds = 750) {
      let _this = this;
      setTimeout(() => {
        _this.$Progress.finish();
      }, afterSeconds);
    },
    isNumber(event, message) {
      if (!/\d/.test(event.key) && (event.key !== "." || /\./.test(message))) {
        return event.preventDefault();
      }
    },
  },
  mounted() {
    //
  },
  data() {
    return {
      //
    };
  },
}
