<template>
  <div class="who-we-are container">
    <div class="row" style="margin: 0;">
      <div class="col-12">
        <h2 class="main-title">
          <i class="fa fa-flag has-margin" aria-hidden="true"></i>
          <b>{{ $t('home.whoWeAre.headerTitle') }}</b>
        </h2>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
        <div class="box">
          <h2><b>{{ $t('home.whoWeAre.title') }}</b></h2>
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <div class="box">
          <article>
            <h5>{{ $t('home.whoWeAre.text_1') }}</h5>
            <h5>{{ $t('home.whoWeAre.text_2') }}</h5>
            <h5>{{ $t('home.whoWeAre.text_3') }}</h5>
            <h5>{{ $t('home.whoWeAre.text_4') }}</h5>
          </article>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  components: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.who-we-are {
  padding: 33px 0;
  //
  .main-title {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: auto;
      background-color: $main_color;
      height: 2px;
      width: 225px;
    }
  }
  //
  .box {
    //border: 1px solid silver;
    margin-bottom: 10px;
    padding: 20px;
  }
}


html[dir=rtl], body.layout-rtl {  
  .who-we-are {
    .main-title {
      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: auto;
        right: 0;
        background-color: $main_color;
        height: 2px;
        width: 225px;
      }
    }
  }
}
</style>