<template>
  <div class="about-us height-fixer">

    <app-background-header
      v-bind:header="$t('nav.about')"
      v-bind:title="``"
      v-bind:src="`${this.$engine.asset('images/bg.jpg')}`"
    />

    <div class="container">
      <app-loading v-bind:size="75" v-bind:mtb="70" v-if="isLoading" />
      <div class="abouts" v-else>
        <app-about-row v-for="(about, i) in abouts" v-bind:about="about" v-bind:key="i" v-bind:i="i" />
      </div>
    </div>
  </div>
</template>

<script>
import AboutRow from '@/components/about/AboutRow.vue';
export default {
  props: [],
  components: {
    'app-about-row': AboutRow,
  },
  data() {
    return {
      abouts: [],
      isLoading: true,
    };
  },
  methods: {},
  mounted() {
    let _this = this;
    this.$engine.updateTitlePage(this.$t('nav.about'));
    //
    this.$axios.get(this.$api, {
      params: {
        type: 'get-about'
      }
    })
    .then(response => {
      let data = response.data;
      this.abouts = data.abouts;
      //console.log(data);
    })
    .finally(() => {
      this.isLoading = false;
      this.finishProgress();
    });
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>
