<template>
  <div class="contact-us height-fixer">
    <app-background-header
      v-bind:header="$t('nav.contact')"
      v-bind:title="$t('contact.title')"
      v-bind:bg_is_cover="false"
      v-bind:src="this.$engine.asset('images/bg-3.jpg')"
    />

    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <form v-on:submit.prevent="dropMessage">
            <div class="form-group">
              <label for="fullname">{{ $t('contact.form.fullname') }}</label>
              <input
                type="text"
                class="form-control"
                id="fullname"
                name="fullname"
                required
                v-model="formFields.fullname"
                v-bind:disabled="isDisabled"
                aria-describedby="fullnameHint"
                v-bind:placeholder="$t('contact.form.fullname')" />
              <small id="fullnameHint" class="form-text text-muted">
                {{ $t('contact.form.fullnameHint') }}
              </small>
            </div>
            <div class="form-group">
              <label for="email">{{ $t('contact.form.email') }}</label>
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                required
                v-model="formFields.email"
                v-bind:disabled="isDisabled"
                aria-describedby="emailHelp"
                v-bind:placeholder="$t('contact.form.email')" />
              <small id="emailHelp" class="form-text text-muted">
                {{ $t('contact.form.emailHint') }}
              </small>
            </div>
            <div class="form-group">
              <label for="phone">{{ $t('contact.form.phone') }}</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                required
                v-model="formFields.phone"
                v-bind:disabled="isDisabled"
                aria-describedby="phoneHint"
                v-numericOnly
                v-bind:placeholder="$t('contact.form.phone')" />
              <small id="phoneHint" class="form-text text-muted">
                {{ $t('contact.form.phoneHint') }}
              </small>
            </div>
            <div class="form-group">
              <label for="address">{{ $t('contact.form.address') }}</label>
              <input
                type="text"
                class="form-control"
                id="address"
                name="address"
                required
                v-model="formFields.address"
                v-bind:disabled="isDisabled"
                aria-describedby="addressHint"
                v-bind:placeholder="$t('contact.form.address')" />
              <small id="addressHint" class="form-text text-muted">
                {{ $t('contact.form.addressHint') }}
              </small>
            </div>
            <div class="form-group">
              <label for="title">{{ $t('contact.form.title') }}</label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                required
                v-model="formFields.title"
                v-bind:disabled="isDisabled"
                aria-describedby="titleHint"
                v-bind:placeholder="$t('contact.form.title')" />
              <small id="titleHint" class="form-text text-muted">
                {{ $t('contact.form.titleHint') }}
              </small>
            </div>
            <div class="form-group">
              <label for="message">{{ $t('contact.form.message') }}</label>
              <textarea
                type="text"
                class="form-control"
                id="message"
                name="message"
                required
                v-model="formFields.message"
                v-bind:disabled="isDisabled"
                aria-describedby="messageHint"
                v-bind:placeholder="$t('contact.form.message')">
                </textarea>
              <small id="messageHint" class="form-text text-muted">
                {{ $t('contact.form.messageHint') }}
              </small>
            </div>
            <button type="submit" class="btn btn-primary mx-auto d-block">{{ $t('contact.form.send') }}</button>
            <div class="alert alert-warning text-center" v-if="isResponsed" v-text="responseText"></div>
            <app-loading v-bind:size="25" v-if="isPosting" />
          </form>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <h6>{{ $t('contact.hint') }}</h6>
          <hr />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3218.963295075572!2d44.00162534283991!3d36.216087085909464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4007220f177d652b%3A0x8b0a5798c0edca0f!2sMRF%20Quattro!5e0!3m2!1sen!2siq!4v1573418847661!5m2!1sen!2siq"
            width="100%" height="300" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: [],
  computed: {},
  components: {},
  data() {
    return {
      isDisabled: false,
      isPosting: false,
      isResponsed: false,
      responseText: "",
      formFields: {},
    };
  },
  methods: {
    dropMessage() {
      this.isDisabled = true;
      this.isPosting = true;
      this.isResponsed = false;
      this.responseText = '';
      this.startProgress();
      //console.log(this.formFields);
      //
      this.$axios.post(this.$api, {
        type: 'drop-message',
        ...this.formFields
      })
      .then(response => {
        let data = response.data;
        this.isResponsed = true;
        // this.responseText = data.status;
        //console.log(data);
        if (data.status === 'ok') {
          this.responseText = this.$t('contact.messageSentSuccessfully');
          this.formFields = {};
        }
        else {
          this.responseText = this.$t('errors.messageNotSent');
        }
      })
      .finally(() => {
        this.isDisabled = false;
        this.isPosting = false;
        this.finishProgress();
      });
    },
  },
  mounted() {
    this.finishProgress();
    this.$engine.updateTitlePage(this.$t('nav.contact'));
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.contact-us {
  padding-bottom: 25px;
  //
  .alert {
    margin: 10px 0;
  }
}
</style>