<template>
  <header>

    <app-language-switcher />

    <app-phone-popup />

    <div class="top-header">
      <div class="container">
        <div class="col-md-6 slogan go-left center-in-less-than-768" style="margin: 5px 0;">
          {{ $t('nav.top.slogan') }}
        </div>
        <div class="col-xl-4 col-lg-5 col-md-6 col-12 go-right">
          <ul class="top-links" style="margin-top: 5px;">
            <li><a href="javascript://" v-on:click="openLanguageSwitcherPopup">{{ $t('nav.top.languages') }}</a></li>
            <li><router-link to="/contact">{{ $t('nav.contact') }}</router-link></li>
            <li><router-link to="/about">{{ $t('nav.about') }}</router-link></li>
            <li><a href="javascript://"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="javascript://"><i class="fab fa-instagram"></i></a></li>
            <li><a href="mailto:info@majarahgroup.com"><i class="fas fa-envelope"></i></a></li>
            <li><a href="javascript://" v-on="{click: openPhonePopup}"><i class="fas fa-phone"></i></a></li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="bottom-header">
      <div class="container-fluid">
        <div class="logo-brand">
          <router-link to="/">
            <img src="@/assets/images/logo.jpg" alt="" />
          </router-link>
          <button class="btn btn-primary nav-toggler" v-on:click="toggleNav">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <div class="nav-links">
          <ul ref="nav">
            <li><router-link to="/sections">{{ $t('nav.sections') }}</router-link></li>
            <li><router-link to="/about">{{ $t('nav.about') }}</router-link></li>
            <li><router-link to="/contact">{{ $t('nav.contact') }}</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from '@/components/layout/LanguageSwitcher.vue';
import PhonePopup from '@/components/layout/PhonePopup.vue';
export default {
  props: [],
  components: {
    'app-language-switcher': LanguageSwitcher,
    'app-phone-popup': PhonePopup,
  },
  computed: {},
  methods: {
    toggleNav() {
      let nav = this.$refs.nav;
      nav.classList.toggle('opened');
    },
    openLanguageSwitcherPopup() {
      return jQuery('.language-switcher').addClass('active');
    },
    openPhonePopup() {
      return jQuery('.phone-popup').addClass('active');
    },
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_includes.scss';
//
.fixed-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(black, .75);
  visibility: hidden;
  opacity: 0;
  @include transitionDuration(.25s);
  //
  .fixed-modal {
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -33%);
    background-color: #fff;
    padding: 15px;
    width: 100%;
    max-width: 550px;
    @include radius(4px);
    @include transitionDuration(.25s);
  }
  //
  &.active {
    visibility: visible;
    opacity: 1;
    //
    .fixed-modal {
      top: 38%;
      transform: translate(-50%, -38%);
    }
  }
  //
  &.language-switcher {
    .language-modal {
      .languages {
        > li {
          padding: 10px;
          cursor: pointer;
          //
          &:hover {
            background-color: $lighted_color_3;
          }
        }
      }
    }
  }
}
</style>