<template>
  <div class="sections height-fixer">
    <app-background-header
      v-if="id_section === undefined"
      v-bind:header="id_section === undefined ? $t('nav.sections') : ''"
      v-bind:title="``"
      v-bind:src="this.$engine.asset('images/bg.jpg')"
    />

    <div>
      <app-loading v-if="isLoading" />
      
      <div v-else-if="id_section === undefined" class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="(section, i) in sections" v-bind:key="i">
            <app-section v-bind:section="section" />
          </div>
        </div>
      </div>

      <div v-else>
        <app-section-view
          v-bind:section="sections[0]"
          v-bind:sections="sections"
        />
      </div>
    </div>

  </div>
</template>

<script>
import SectionBox from '@/components/sections/SectionBox.vue';
import SectionView from '@/components/sections/SectionView.vue';
//
export default {
  name: "Sections",
  props: [],
  computed: {
    id_section() {
      return this.$route.params.id_section;
    },
  },
  components: {
    'app-section': SectionBox,
    'app-section-view': SectionView,
  },
  data() {
    return {
      isLoading: true,
      sections: [],
    };
  },
  methods: {},
  mounted() {
    this.$engine.updateTitlePage(this.$t('nav.sections'));
    //
    let _this = this;
    this.$axios.get(this.$api, {
      params: {
        type: 'get-sections',
        id_section: this.id_section,
      }
    })
    .then(response => {
      let data = response.data;
      this.sections = data.sections;
      this.isLoading = false;
    })
    .catch(() => {
      //
    })
    .finally(() => {
      this.finishProgress();
      //
    });
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
</style>