<template>
  <div class="what-we-do">
    <div class="parallax excepted">
      <div class="centered-vertically">
        <div class="container">
          <h2 class="force-center force-rtl">
            <i class="fa fa-quote-left" aria-hidden="true"></i>
            <span class="slogan-span">{{ slogan }}</span>
            <i class="fa fa-quote-right" aria-hidden="true"></i>
          </h2>
          <hr />
          <h6 class="force-center"><b><i>{{ speaker }}</i></b></h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['slogan', 'speaker'],
  computed: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.what-we-do {
  background: fixed center center no-repeat
    url('../../assets/images/bg.jpg');
  background-size: cover;
  position: relative;
  min-height: 325px;
  //
  .parallax {
    padding: 25px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .67);
    //
    .centered-vertically {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      //
      h2 {
        span {
          //display: inline-block;
          margin: 0 20px;
        }
      }
      //
      hr {
        border-top-color: $lighted_color_2;
        max-width: 200px;
      }
    }
  }
}
</style>