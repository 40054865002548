<template>
  <div class="companies container-fluid-">
    <h1 class="text-center">{{ $t('home.branches.title') }}</h1>
    <hr />
    <vue-carousel
      v-bind:autoplay="true"
      v-bind:loop="false"
      v-bind:autoplayTimeout="3000"
      v-bind:autoplaySpeed="1000"
      v-bind:responsive="responsive"
      v-bind:margin="33"
      v-bind:stagePadding="25"
      v-bind:nav="false"
      v-bind:center="true"
      v-bind:dots="false">
      <img
        v-for="(branch, i) in branches"
        v-bind:key="i"
        v-bind:src="$engine.storage(`branches/${branch.slide_image}`)"
        v-bind:alt="$engine.lang_json(branch.slide_title, lang)"
      />
    </vue-carousel>
  </div>
</template>

<script>
export default {
  props: ['branches'],
  computed: {
    responsive() {
      return {
        0: {
          items: 2,
        },
        481: {
          items: 3,
        },
        576: {
          items: 4,
        },
        768: {
          items: 4,
        },
        991: {
          items: 6,
        },
        1200: {
          items: 6,
        },
      };
    },
  },
  components: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.companies {
  padding: 50px 0;
}
//
hr {
  margin: 15px auto 33px;
  max-width: 200px;
  border-top-width: 2px;
}
</style>