<template>
  <div class="contact-us">
    <div class="parallax excepted">
      <div class="centered-vertically force-center">
        <h2>{{ $t('contact.title') }}</h2>
        <p>{{ $t('contact.hint') }}</p>
        <router-link to="/contact" class="btn btn-primary">
          <span>{{ $t('nav.contact') }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  components: {},
  methods: {},
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
.contact-us {
  background: center center no-repeat url('../../assets/images/bg-2.jpeg');
  background-size: cover;
  min-height: 320px;
  position: relative;
  //
  .parallax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .67);
    color: #fff;
    //
    .centered-vertically {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px 33px;
    }
  }
}
</style>