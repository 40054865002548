<template>
  <div class="fixed-layout phone-popup"
    ref="phone_popup"
    v-on:click.self="closePhonePopup">
    <div class="fixed-modal ">
      <h4>
        <i class="fa fa-phone has-margin" aria-hidden="true"></i>
        <span>{{ $t('modals.phonePopup.title') }}</span>
      </h4>
      <hr />
      <ul>
        <li>
          <h5>{{ $t('modals.phonePopup.details.sami.name') }}:</h5>
          <ul class="list-unstyled">
            <li>
              {{ $t('contact.form.email') }}:
              <a href="mailto:sami@majarahgroup.com">sami@majarahgroup.com</a>
            </li>
            <li>
              {{ $t('contact.form.phone') }}:
              <span>009647501257111</span>
            </li>
          </ul>
        </li>

        <li>
          <h5>{{ $t('modals.phonePopup.details.menerva.name') }}:</h5>
          <ul class="list-unstyled">
            <li>
              {{ $t('contact.form.email') }}:
              <a href="mailto:menerva@majarahgroup.com">menerva@majarahgroup.com</a>
            </li>
            <li>
              {{ $t('contact.form.phone') }}:
              <span>009647501256111</span>
            </li>
          </ul>
        </li>
        
        <li>
          <h5>{{ $t('modals.phonePopup.details.yad.name') }}:</h5>
          <ul class="list-unstyled">
            <li>
              {{ $t('contact.form.email') }}:
              <a href="mailto:yad@majarahgroup.com">yad@majarahgroup.com</a>
            </li>
            <li>
              {{ $t('contact.form.phone') }}:
              <span>009647704615272</span>
            </li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  components: {},
  data() {
    return {
      //
    };
  },
  methods: {
    closePhonePopup() {
      return this.$refs.phone_popup.classList.remove('active');
    },
  },
  mounted() {
    //
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_includes.scss';
//
ul:not(.list-unstyled) {
  > li {
    margin-bottom: 15px;
  }
}
//
.list-unstyled {
  padding: 0 20px;
  list-style-type: disc !important;
}
</style>
